export default {
    methods: {
        toBase64Img(file, method) {
            if (!file) {
                method(null);
                return;
            }
            const reader = new FileReader();
            reader.addEventListener('load', () => {
                method(reader.result);
            });
            reader.readAsDataURL(file);
        },
    },
};
