<template>
  <v-row>
    <v-col cols="12">
      <v-stepper v-model="currentStep">
        <v-stepper-header>
          <template v-for="(step, i) in steps">
            <v-stepper-step
              :step="step.step + 1"
              :key="title(step)"
              :complete="step.isValid"
              :editable="allStepBeforeIsValid(step.step)"
            >
              {{ title(step) }}
              <small v-if="step.isOptional">{{
                translate("stepper.optional")
              }}</small>
            </v-stepper-step>
            <v-divider v-if="i < steps.length - 1" :key="step.step"></v-divider>
          </template>
        </v-stepper-header>
        <v-stepper-items>
          <v-stepper-content
            v-for="(step, i) in steps"
            :key="i"
            :step="step.step + 1"
            class="stepper-content"
          >
            <v-card>
              <v-card-text>
                <component
                  v-bind:is="step.component"
                  :title="title(step)"
                  :step="step.step"
                  :description="
                    description(step.component, step.description, step.type)
                  "
                  :type="step.type"
                  :parent-type="step.parentType"
                  @valid-step="validStep"
                ></component>
              </v-card-text>
              <v-card-actions>
                <v-btn
                  @click="prev"
                  :disabled="!canPrev"
                  class="col-6"
                  x-large
                  >{{ translate("stepper.prev") }}</v-btn
                >
                <v-btn
                  @click="next"
                  :disabled="!canNext"
                  class="col-6"
                  v-if="step.step < steps.length - 1"
                  x-large
                  >{{ translate("stepper.next") }}</v-btn
                >
                <v-btn
                  color="success"
                  @click="validation"
                  class="col-6"
                  v-if="step.step === steps.length - 1"
                  x-large
                  >{{ translate("stepper.sendRequest") }}</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-col>
  </v-row>
</template>

<script>
import StepperBus from "./stepper-bus";
import Stepper from "@/constants/stepper-constants";
import ClientInformation from "./steps/client-information";
import ThemePicker from "./steps/theme-picker";
import PictosPicker from "./steps/pictos-picker";
import SendMail from "./steps/send-mail";
import Modules from "./steps/modules";

export default {
  name: "stepper",
  components: {
    ClientInformation,
    ThemePicker,
    PictosPicker,
    SendMail,
    Modules,
  },
  data: () => ({
    currentStep: 1,
    steps: [...Stepper.steps],
  }),
  computed: {
    canPrev() {
      return this.currentStep > 1;
    },
    canNext() {
      return (
        this.currentStep < this.steps.length &&
        this.steps[this.currentStep - 1].isValid
      );
    },
  },
  methods: {
    prev() {
      this.currentStep--;
    },
    next() {
      this.currentStep++;
    },
    validStep(stepValidation) {
      this.steps[stepValidation.step].isValid = stepValidation.isValid;
    },
    allStepBeforeIsValid(step) {
      for (let index = 0; index < step; index++) {
        if (!this.steps[index].isValid && !this.steps[index].isOptional)
          return false;
      }
      return true;
    },
    validation() {
      StepperBus.$emit("send-mail");
    },
    title(step) {
      return this.translate(
        step.type
          ? `${step.component}.types.${step.type}.title`
          : `${step.component}.title`
      );
    },
    description(component, description, type) {
      return description
        ? this.translate(
            `${component}${type ? ".types." + type : ""}.description`
          )
        : null;
    },
  },
  watch: {
    currentStep(newVal, oldVal) {
      StepperBus.$emit("next-step", oldVal - 1);
    },
  },
};
</script>

<style scoped>
.stepper-content {
  padding: 0;
}
</style>
