import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

import fr from '@/langs/fr';
import en from '@/langs/en';

export default new Vuetify({
    lang: {
        locales: { fr, en },
        current: 'fr',
    },
});
