<template>
  <v-row>
    <v-col cols="12">
      <h2>{{ title }}</h2>
    </v-col>
    <v-col cols="12">
      <v-row>
        <v-col cols="12" md="6">
          <v-form v-model="valid">
            <v-row align="center">
              <v-col cols="12">
                <v-text-field
                  :label="translate('clientInformation.clientNameLabel')"
                  :placeholder="
                    translate('clientInformation.clientNamePlaceholder')
                  "
                  :rules="rules"
                  v-model="licenseCode"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  :label="translate('clientInformation.ReferentLabel')"
                  :placeholder="
                    translate('clientInformation.ReferentPlaceholder')
                  "
                  :rules="rules"
                  v-model="refClient"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-col>
        <v-col
          cols="12"
          md="3"
          offset-md="1"
          class="iframe-container"
          v-if="videoUrl"
        >
          <iframe :src="videoUrl" frameborder="0" allowfullscreen></iframe>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>


<script>
import StepperBus from "@/components/step/stepper-bus";

export default {
  name: "client-information",
  props: {
    step: {
      type: Number,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    refClient: null,
    licenseCode: null,
    valid: false,
    videoUrl: null, //'https://www.youtube.com/embed/RD7CvUGqw3w'
  }),
  mounted() {
    this.$nextTick(() => {
      this.licenseCode = this.$store.state.licenseCode;
      this.refClient = this.$store.state.refClient;
    });
    StepperBus.$on("next-step", this.validStep);
  },
  computed: {
    rules() {
      return [(value) => !!value || this.translate("errors.required")];
    },
  },
  watch: {
    valid() {
      this.$emit("valid-step", {
        step: this.step,
        isValid: this.valid,
      });
    },
  },
  methods: {
    validStep(step) {
      if (this.step !== step) return;
      this.$store.commit("setLicenseCode", this.licenseCode);
      this.$store.commit("setRefClient", this.refClient);
    },
  },
};
</script>

<style scoped>
.iframe-container {
  position: relative;
}

.iframe-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  min-height: 230px;
}
</style>
