import Vue from 'vue';
import VueRouter from 'vue-router';
import Create from '@/views/create.vue';
import Update from '@/views/update.vue';

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'create',
        component: Create,
        meta: {
            title: 'Créer votre CSS',
        },
    },
    {
        path: '/update',
        name: 'update',
        component: Update,
        meta: {
            title: 'Créer votre CSS',
        },
    },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
});

export default router;
