<template>
  <v-dialog v-model="show" scrollable fullscreen persistent>
    <template v-slot:activator="{ on, attrs }">
      <v-btn class="mx-2" fab dark color="primary" v-bind="attrs" v-on="on">
        <v-icon dark> mdi-plus </v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span class="headline">{{ formTitle }}</span>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="save" :disabled="!isFormValid" x-large>
          {{ formValidation }}
        </v-btn>
        <v-btn color="primary" text @click="close" x-large>
          {{ translate("common.cancel") }}
        </v-btn>
      </v-card-title>
      <v-card-subtitle>
        {{ translate("pictoEditor.tooltip") }}
      </v-card-subtitle>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" class="shrink">
              <v-chip
                v-for="(code, i) in pictoCreate.codes"
                :key="i"
                close
                @click:close="removeCode(i)"
                >{{ code }}
              </v-chip>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="newCodes.value"
                label="CODES des entitées DISPATCH associées"
                type="text"
                :append-outer-icon="'mdi-send'"
                @click:append-outer="addCode"
                @keydown.enter="addCode"
                :error="newCodes.IsError"
                :error-messages="newCodes.ErrorMessages"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-tabs fixed-tabs v-model="tabSelected">
              <v-tab href="#picto">{{
                translate("pictoEditor.pictoBank")
              }}</v-tab>
              <v-tab href="#custom">{{
                translate("pictoEditor.customization")
              }}</v-tab>
              <v-tabs-items v-model="tabSelected">
                <v-tab-item value="picto">
                  <v-row>
                    <v-col cols="4" class="shrink" align-self="center">
                      <v-btn color="primary" @click="resetColor()" class="mb-4">
                        {{ translate("pictoEditor.resetColors") }}
                      </v-btn>
                      <input-color
                        v-for="(color, index) in customColors"
                        :key="`color-${index}`"
                        :label="`${translate('pictoEditor.customColor')} ${
                          index + 1
                        }`"
                        v-model="customColors[index]"
                      />
                    </v-col>
                    <v-col cols="8" class="shrink">
                      <v-row>
                        <v-col cols="8" class="shrink">
                          <v-text-field
                            v-model="pictoFilter"
                            label="Filtre"
                            type="text"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12">
                          <v-chip-group
                            active-class="blue lighten-1"
                            column
                            v-model="pictoIndexSelected"
                            class="pictionnary-list"
                            ><v-chip
                              x-large
                              v-for="(picto, i) in pictionaryFiltered"
                              :key="i"
                              :active="picto.isVisible"
                              :value="i"
                              :class="
                                picto.name === pictoCreate.picto.name
                                  ? 'v-chip--active'
                                  : ''
                              "
                              class="ma-1"
                              @click="
                                pictoCreate.picto = Object.assign({}, picto)
                              "
                            >
                              <span
                                :style="picto.style"
                                class="svg-icon"
                              ></span>
                              {{ translatePicto(picto.name) }}
                            </v-chip></v-chip-group
                          >
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-tab-item>
                <v-tab-item value="custom">
                  <v-col cols="12">
                    <v-file-input
                      :rules="imageRules"
                      accept="image/png, image/jpeg, image/bmp"
                      :placeholder="translate('common.chooseImage')"
                      prepend-icon="mdi-image"
                      :label="translate('pictoEditor.customPicto')"
                      @change="changePicto"
                      v-model="inputValue"
                    ></v-file-input>
                  </v-col>
                </v-tab-item>
              </v-tabs-items>
            </v-tabs>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import pictosPicker from "@/constants/pictos-picker-constants";
import inputColor from "./input-color.vue";

export default {
  components: { inputColor },
  name: "picto-editor",
  props: {
    dialog: {
      type: Boolean,
      default: false,
      required: true,
    },
    codesAllreadyUsed: {
      type: Array,
    },
    editedPicto: {
      type: Object,
      required: true,
    },
    editedIndex: {
      type: Number,
      required: true,
    },
    colors: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    newCodes: {
      value: "",
      IsError: false,
      ErrorMessages: [],
    },
    pictoCreate: {
      picto: {
        name: "",
        source: "",
        style: "",
        isCustom: false,
        colors: null,
      },
      codes: [],
    },
    pictoFilter: "",
    pictionary: [],
    customColors: [],
    show: false,
    inputValue: [],
    tabSelected: "picto",
    pictoIndexSelected: null,
  }),
  mounted() {
    this.$nextTick(() => {
      this.show = this.dialog;
      this.customColors = [...this.colors];
    });
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1
        ? this.translate("pictoEditor.newPicto")
        : this.translate("pictoEditor.editPicto");
    },
    formValidation() {
      return this.editedIndex === -1
        ? this.translate("common.add")
        : this.translate("common.modify");
    },
    isFormValid() {
      return (
        this.pictoCreate.codes.length > 0 && !!this.pictoCreate.picto.source
      );
    },
    pictionaryFiltered() {
      for (let index = 0; index < this.pictionary.length; index++) {
        const picto = this.pictionary[index];
        const isVisible =
          this.translatePicto(picto.name)
            .toLowerCase()
            .indexOf(this.pictoFilter.toLowerCase()) >= 0;
        picto.isVisible = isVisible;
      }
      return this.pictionary;
    },
    imageRules() {
      return [
        (value) =>
          !value || value.size < 2000000 || this.translate("errors.imageRules"),
      ];
    },
  },
  methods: {
    addCode() {
      this.newCodes.IsError = false;
      this.newCodes.ErrorMessages = [];
      if (!this.newCodes.value) {
        this.newCodes.IsError = true;
        this.newCodes.ErrorMessages.push(this.translate("errors.notNull"));
      }
      if (this.newCodes.value.indexOf(" ") >= 0) {
        this.newCodes.IsError = true;
        this.newCodes.ErrorMessages.push(this.translate("errors.spaceBetween"));
      }
      if (
        this.pictoCreate.codes.includes(this.newCodes.value) ||
        (this.codesAllreadyUsed &&
          this.codesAllreadyUsed.includes(this.newCodes.value))
      ) {
        this.newCodes.IsError = true;
        this.newCodes.ErrorMessages.push(this.translate("errors.alreadyExist"));
      }
      if (!this.newCodes.IsError) {
        this.pictoCreate.codes.push(this.newCodes.value);
        this.newCodes.value = "";
      }
    },
    removeCode(index) {
      this.pictoCreate.codes.splice(index, 1);
    },
    save() {
      if (JSON.stringify(this.customColors) !== JSON.stringify(this.colors))
        this.pictoCreate.picto.colors = [...this.customColors];
      this.$emit("picto-save", {
        picto: Object.assign({}, this.pictoCreate.picto),
        codes: this.pictoCreate.codes.slice(),
      });
      this.close();
    },
    close() {
      this.show = false;
      this.$emit("picto-close");
      this.$nextTick(() => {
        this.newCodes = {
          value: "",
          IsError: false,
          ErrorMessages: [],
        };
        this.inputValue = [];
        this.pictoCreate = {
          picto: {
            name: "",
            source: "",
            style: "",
            isCustom: false,
            colors: null,
          },
          codes: [],
        };
        this.pictoIndexSelected = null;
        this.tabSelected = "picto";
      });
    },
    toBase64Img(file, method) {
      if (!file) {
        method(null);
        return;
      }
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        method(reader.result);
      });
      reader.readAsDataURL(file);
    },
    changePicto(file) {
      this.toBase64Img(file, (result) => {
        this.pictoCreate.picto.source = result.replace(/.*,/, "");
        this.pictoCreate.picto.style = `background-image: url("${result}");`;
        this.pictoCreate.picto.name = file.name;
        this.pictoCreate.picto.isCustom = true;
      });
    },
    translatePicto(name) {
      return this.translate(
        `pictoEditor.pictos.${name.replaceAll(" ", "_").replace("+", "")}`
      );
    },
    svgStyle(svg) {
      let svgStr = svg.formatColor(
        this.customColors[4],
        this.customColors[3],
        this.customColors[2],
        this.customColors[1],
        this.customColors[0]
      );
      return `background-image: url("data:image/svg+xml;utf8,${escape(
        svgStr
      )}") !important;`;
    },
    setPictionary() {
      this.pictionary = pictosPicker.pictos.map((p) => ({
        ...p,
        isVisible: true,
        style: this.svgStyle(p.source),
      }));
    },
    resetColor() {
      this.customColors = [...this.colors];
    },
  },
  watch: {
    dialog(val) {
      this.show = val;
      val || this.close();
    },
    editedPicto(val) {
      this.pictoCreate = {
        picto: Object.assign({}, val.picto),
        codes: val.codes.slice(),
      };
      this.pictoIndexSelected = this.pictionary.findIndex(
        (p) => p.name === val.picto.name
      );
      if (val.picto.colors) this.customColors = [...val.picto.colors];
    },
    colors() {
      this.customColors = [...this.colors];
    },
    customColors: {
      handler: function () {
        this.setPictionary();
        if (this.pictoCreate.picto.style.length)
          this.pictoCreate.picto.style = this.svgStyle(
            this.pictoCreate.picto.source
          );
      },
      deep: true,
    },
  },
};
</script>

<style>
.svg-icon {
  width: 60px;
  height: 60px;
  display: block;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 60px 60px;
}

.pictionnary-list .v-slide-group__content {
  max-height: 300px;
  overflow-y: auto;
}
</style>
