<template>
  <v-row>
    <v-col cols="12">
      <h2>{{ title }}</h2>
      <h3>{{ description }}</h3>
    </v-col>
    <v-col cols="12" v-if="parentType" class="parent-pictos">
      <h3>{{ parentPictosLabel }}</h3>
      <v-chip
        v-for="picto in parentPictos"
        :key="picto.picto.name"
        :ripple="false"
      >
        <span :style="picto.picto.style" class="svg-icon"></span
        >{{ codeList(picto.codes) }}
      </v-chip>
    </v-col>
    <v-col cols="12">
      <v-data-table
        :headers="headers"
        :items="pictos"
        class="elevation-1"
        :disable-pagination="true"
        :disable-filtering="true"
        :disable-sort="true"
        hide-default-footer
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>{{
              translate("pictosPicker.addPicto")
            }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <picto-editor
              :dialog="dialogEditor"
              @picto-save="save"
              @picto-close="closeEditor"
              :codes-allready-used="codesAllreadyUsed"
              :edited-picto="editedPicto"
              :edited-index="editedIndex"
              :colors="colors"
            ></picto-editor>

            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="headline text-center">{{
                  translate("pictosPicker.suppPicto")
                }}</v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="closeDelete">{{
                    translate("common.cancel")
                  }}</v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="deletePictoConfirm"
                    >{{ translate("common.yes") }}</v-btn
                  >
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:item.picto="{ item }">
          <span :style="item.picto.style" class="svg-icon"></span>
        </template>
        <template v-slot:item.codes="{ item }">
          <v-chip v-for="(code, i) in item.codes" :key="i">
            {{ code }}
          </v-chip>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon small class="mr-2" @click="editPicto(item)">
            mdi-pencil
          </v-icon>
          <v-icon small @click="deletePicto(item)"> mdi-delete </v-icon>
        </template>
        <template v-slot:no-data>
          <v-btn color="primary" @click="dialogEditor = true" x-large>
            {{ translate("pictosPicker.newPicto") }}
          </v-btn>
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>


<script>
import StepperBus from "@/components/step/stepper-bus";
import PictoEditor from "@/components/picto-editor";

export default {
  name: "pictos-picker",
  components: {
    PictoEditor,
  },
  props: {
    step: {
      type: Number,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
    },
    type: {
      type: String,
    },
    parentType: {
      type: String,
    },
  },
  data: () => ({
    dialogEditor: false,
    dialogDelete: false,
    pictos: [],
    editedIndex: -1,
    colors: [],
    editedPicto: {
      picto: {
        name: "",
        source: "",
        style: "",
        isCustom: false,
        colors: null,
      },
      codes: [],
    },
  }),
  mounted() {
    this.$nextTick(() => {
      if (this.variationColor) this.setColors(this.variationColor.colors);
      if (this.$store.state.pictos[this.type])
        this.pictos = this.$store.state.pictos[this.type];
      this.validStep(this.step);
    });
    StepperBus.$on("next-step", this.validStep);
  },
  computed: {
    codesAllreadyUsed() {
      const codes = [];
      for (let index = 0; index < this.pictos.length; index++) {
        const picto = this.pictos[index];
        codes.push(...picto.codes);
      }
      return codes;
    },
    variationColor() {
      return this.$store.state.variationColor;
    },
    headers() {
      return [
        {
          text: this.translate("pictosPicker.headers.pictos"),
          align: "start",
          sortable: false,
          value: "picto",
          class: "text-h6",
        },
        {
          text: this.translate("pictosPicker.headers.codes"),
          value: "codes",
          sortable: false,
          class: "text-h6",
        },
        {
          text: this.translate("pictosPicker.headers.actions"),
          value: "actions",
          sortable: false,
          class: "text-h6",
        },
      ];
    },
    parentPictos() {
      return this.parentType ? this.$store.state.pictos[this.parentType] : [];
    },
    parentPictosLabel() {
      return this.parentType
        ? `${this.translate("pictosPicker.parentPictos")} ${this.translate(
            "pictosPicker.types." + this.parentType + ".title"
          )}`
        : null;
    },
  },
  methods: {
    setColors(colors) {
      const colorsArray = Object.values(colors);
      this.colors = [];
      for (let index = 0; index < 2; index++) {
        this.colors.push(colorsArray[index]);
      }
      this.colors.push(this.colors[1].colorLuminance(-0.5));
      this.colors.push(this.colors[0].colorLuminance(-0.1));
      this.colors.push("#ffffff");
    },
    validStep(step) {
      if (step !== this.step) return;
      this.$store.commit("setPictos", {
        type: this.type,
        pictos: this.pictos.slice(),
      });
    },
    save(editedPicto) {
      if (this.editedIndex > -1) {
        this.$set(this.pictos, this.editedIndex, editedPicto);
      } else {
        this.pictos.push(editedPicto);
      }
    },
    closeEditor() {
      this.dialogEditor = false;
      this.$nextTick(() => {
        this.editedPicto = {
          picto: {
            name: "",
            source: "",
            style: "",
            isCustom: false,
            colors: null,
          },
          codes: [],
        };
        this.editedIndex = -1;
      });
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedPicto = {
          picto: {
            name: "",
            source: "",
            style: "",
            isCustom: false,
            colors: null,
          },
          codes: [],
        };
        this.editedIndex = -1;
      });
    },
    deletePictoConfirm() {
      this.pictos.splice(this.editedIndex, 1);
      this.closeDelete();
    },
    editPicto(picto) {
      this.editedIndex = this.pictos.indexOf(picto);
      this.editedPicto = picto;
      this.dialogEditor = true;
    },
    deletePicto(picto) {
      this.editedIndex = this.pictos.indexOf(picto);
      this.editedPicto = picto;
      this.dialogDelete = true;
    },
    codeList(codes) {
      return codes.join(" / ");
    },
  },
  watch: {
    dialogDelete(val) {
      val || this.closeDelete();
    },
    variationColor() {
      if (this.variationColor)
        this.setColors(this.$store.state.variationColor.colors);
    },
  },
};
</script>

<style>
.parent-pictos h3 {
  padding-bottom: 16px;
}

.parent-pictos .svg-icon,
td .svg-icon {
  width: 60px;
  height: 60px;
  display: block;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 60px 60px;
}
</style>
