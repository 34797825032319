<template>
  <v-row v-if="colors" no-gutters>
    <v-col
      cols="12"
      :md="md"
      v-for="color in colors"
      :key="color.name"
      no-gutters
    >
      <input-color
        v-model="color.value"
        :label="translate(`${translateField}.colors.${color.name}`)"
        :key="color.name"
        @input="change"
      ></input-color>
    </v-col>
  </v-row>
</template>

<script>
import InputColor from "@/components/input-color";

export default {
  name: "input-color-group",
  components: {
    InputColor,
  },
  props: {
    value: {
      type: Object,
      default: null,
    },
    translateField: {
      type: String,
      required: true,
    },
    md: {
      type: Number,
      default: 12,
    },
  },
  data() {
    return {
      colors: this.value
        ? Object.entries(this.value.colors).map((c) => ({
            value: c[1],
            name: c[0],
          }))
        : null,
    };
  },
  computed: {
    colorValues() {
      return this.colors.map((c) => c.value);
    },
  },
  methods: {
    change() {
      const colorsObj = {};
      for (let index = 0; index < this.colors.length; index++) {
        const color = this.colors[index];
        colorsObj[color.name] = color.value;
      }
      this.$emit("input", {
        colors: colorsObj,
        name: this.value.name,
        disabled: this.value.disabled,
      });
    },
  },
  watch: {
    value: {
      handler() {
        this.colors = this.value
          ? Object.entries(this.value.colors).map((c) => ({
              value: c[1],
              name: c[0],
            }))
          : null;
      },
      deep: true,
    },
  },
};
</script>

<style>
</style>
