import en from 'vuetify/lib/locale/en';

export default {
    ...en,
    create: {
        title: 'Customization request for Dispatch Web',
        subTitle: 'Please choose the elements to personalize your space DispatchWeb',
    },
    update: {
        title: 'Update client CSS',
        subTitle: 'Please select file "config.json"',
    },
    uploadConfig: {
        file: 'File',
    },
    clientInformation: {
        title: 'Customer information',
        clientNameLabel: 'Client name (Dispatch License Code)',
        clientNamePlaceholder: 'Transport company',
        ReferentLabel: 'Dispatch Web referent at the customer',
        ReferentPlaceholder: 'Name / phone number / email',
    },
    modules: {
        title: 'Configuration des modules',
        colorChoice: 'Colors',
    },
    ecommerce: {
        title: 'E-Commerce',
        isActive: 'Configure E-commerce ?',
        colors: {
            primary: 'Primary color',
            secondary: 'Secondary color',
            accent: 'Accent color',
            error: 'Error color',
            info: 'Info color',
            success: 'Success color',
            warning: 'Warning color',
            eurekaPrice: 'Price color',
            eurekaSelectedItem: 'Item selected color',
            eurekaCardTitle: 'Step title color',
            eurekaTrash: 'Trash color',
            eurekaLogoFont: 'Logo text color',
            eurekaCo2Positive: 'Positive CO2 color',
            eurekaCo2Negative: 'Negative CO2 color',
            text__addressInfoStepTab: 'Address text color',
        },
    },
    rounds: {
        title: 'Rounds visualization',
        isActive: 'Configure rounds visualization ?',
        colors: {
            primary: 'Primary color',
            secondary: 'Secondary color',
            accent: 'Accent color',
            error: 'Error color',
            info: 'Info color',
            success: 'Success color',
            warning: 'Warning color',
        },
    },
    tracky: {
        title: 'Tracky',
        isActive: 'Configure Tracky ?',
        headerChoice: 'Header',
        colors: {
            mainColor: 'Main color',
            panelsIconsColor: 'Icons color',
            headerBgColor: 'Background color',
        },
        applyThemeColor: 'Apply colors theme',
    },
    pictosPicker: {
        addPicto: 'Add a pictogram',
        suppPicto: 'Delete this pictogram?',
        newPicto: 'New picto',
        parentPictos: 'Pictos',
        headers: {
            pictos: 'Pictos',
            codes: 'CODES of associated DISPATCH entities',
            actions: 'Actions',
        },
        types: {
            packageFamily: {
                title: 'Package family',
                description:
                    'The package families are accessible in Dispatch Web when entering a mission, they help guide the user in order to best choose his package',
            },
            prestaFamily: {
                title: 'Service family',
            },
            presta: {
                title: 'Service Stage',
            },
        },
    },
    themePicker: {
        title: 'Theme configuration',
        themeAndColorChoice: 'Theme & colors',
        themeLabel: 'Theme choice',
        headerAndFooterChoice: 'header & footer',
        typoAndBackgroundChoice: 'Font & background',
        typoLabel: 'Font',
        backgroundLabel: 'Background site',
        backgroundColor: 'Background color',
        backgroundImage: 'Background image',
        colorHint: 'examples of predefined colors that can be applied on Dispatc hWeb',
        colorLabel: 'Color variations',
        headerFooterHint: 'If no image is selected, the default Dispatch image will be displayed',
        headerLabel: 'Header',
        headerImageLabel: 'Ideal size:',
        headerPositionLabel: 'Header position',
        headerColorLabel: 'Background color for header',
        headerSizeLabel: 'Size of header image',
        footerLabel: 'Footer',
        footerImageLabel: 'Ideal size:',
        footerPositionLabel: 'Footer position',
        footerColorLabel: 'Background color for footer',
        footerHiddenLabel: 'Hide footer',
        footerSizeLabel: 'Size of footer image',
        colors: {
            mainColor: 'Main color',
            mainColorRegular: 'Main color',
            stepColorInactive: 'Inactive step color',
            searchColorRegular: 'Search color',
            payColorRegular: 'Payment color',
            stepColorActive: 'Active step color',
            stepColorTextActive: 'Text color for active step',
            stepColorCircleActive: 'Circle color for active step',
            stepColorNumberActive: 'Number color for active step',
            tabColor: 'Tab color',
        },
    },
    sendMail: {
        title: 'Finalization of the request',
        comment: 'Comment',
        cssUrl: 'Customize url',
        cssUrlHint: 'Url Sample: "client" | "client/agency" | "client/regulator"',
        sendMailTitle: 'Sending request succesfully',
        sendMailErrorTitle: 'Sending request fail',
        sendMailErrorMessage: 'Please contact support team or retry later',
        sendMailSuccessMessage: 'Do you want customize a new DispatchWeb ?',
    },
    stepper: {
        optional: 'Optional',
        prev: 'Previous step',
        next: 'Next Step',
        sendRequest: 'Send the request',
    },
    inputColor: {
        caption: 'Click on the button to select a color',
    },
    demo: {
        refreshButton: 'Show rendering of the theme',
    },
    typoPicker: {
        fontLabel: 'Font type',
        sizeLabel: 'Font size',
        colorLabel: 'Font color',
    },
    pictoEditor: {
        tooltip: 'Please respect the uppercase / lowercase of service codes.  NSpaces in codes are not allowed',
        pictoBank: 'PictoBank',
        customization: 'Custom',
        newPicto: 'New picto',
        editPicto: 'Edit picto',
        customPicto: 'Custom picto',
        customColor: 'Color',
        resetColors: 'Reset colors by default',
        pictos: {
            Other: 'Other',
            Truck: 'Truck',
            Plane: 'Plane',
            Car: 'Car',
            Moto: 'Moto',
            Scooter: 'Scooter',
            Boat: 'Boat',
            Handling: 'Handling',
            Helicopter: 'Helicopter',
            Drone: 'Drone',
            Moving: 'Moving',
            Concierge: 'Concierge',
            Pickup: 'Van',
            Bus: 'Bus',
            Electric_vehicle: 'Electric vehicle',
            Executive_vehicle: 'Executive vehicle',
            Bike: 'Bike',
            Vehicle_for_people_with_reduced_mobility: 'Vehicle for people with reduced mobility',
            Vehicle_with_tailgate: 'Vehicle with tailgate',
            Ecological_vehicle: 'Ecological vehicle',
            Emergency: 'Emergency',
            Trike_bike: 'Trike bike',
            Moto_taxi: 'Moto taxi',
            Taxi: 'Taxi',
            Hotel_reservation: 'Hotel reservation',
            Roller: 'Roller',
            Tractor_only: 'Tractor only',
            Truck_3_axles: 'Truck 3 axles',
            Truck__trailer: 'Truck + trailer',
            Pedestrian: 'Pedestrian',
            Computer_failure: 'Computer failure',
            Failure: 'Failure',
            Security: 'Security',
            Van: 'Van',
            Truck_2_trailers: 'Truck 2 trailers',
            Truck_tanker: 'Truck tanker',
            Truck_large_cab: 'Truck large cab',
            Low_trailer: 'Low trailer',
            Truck_5_axles: 'Truck 5 axles',
            Truck_4_axles: 'Truck 4 axles',
            Truck_heavy_weight: 'Truck heavy weight',
            Electric_vehicle_2: 'Electric vehicle 2',
            Folds: 'Folds',
            Parcel: 'Parcel',
            Bulky: 'Bulky',
            Palette: 'Palette',
            Electronics: 'Electronics',
            Blood: 'Blood',
            Health: 'Health',
            Group: 'Group',
            Reduced_mobility: 'Reduced mobility',
            Person: 'Person',
            Vegetal: 'Vegetal',
            Food: 'Food',
            Luggage: 'Luggage',
            Gift: 'Gift',
            Chemical: 'Chemical',
            Waste: 'Waste',
            Containers: 'Containers',
            Mode: 'Mode',
            Cold: 'Cold',
            Event: 'Event',
            Luxury: 'Luxury',
            Art_Object: 'Art Object',
            Furniture: 'Furniture',
            Hazardous_material: 'Hazardous material',
            Empty_container: 'Empty container',
            Container_full: 'Container full',
            Tire: 'Tire',
            Windshield: 'Windshield',
        },
    },
    errors: {
        required: 'Required',
        imageRules: 'Image size must be less than 2MB',
        notNull: 'Cannot be empty',
        spaceBetween: 'Contains spaces between',
        alreadyExist: 'Already exists',
    },
    common: {
        ok: 'OK',
        cancel: 'Cancel',
        yes: 'Yes',
        add: 'Add',
        no: 'No',
        modify: 'Modify',
        chooseImage: 'choose an image',
        select: 'Select',
    },
};
