<template>
  <v-row justify="start" no-gutters>
    <v-col class="mr-3">
      <h4>{{ label }}</h4>
    </v-col>
    <v-col>
      <v-menu :close-on-content-click="false" v-model="menu" offset-x>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            style="margin-top: -5px"
            :dark="isDark"
            :color="colorValue"
          >
            <v-icon>mdi-water</v-icon>
            <pre>{{ colorValue }}</pre>
          </v-btn>
        </template>
        <v-card>
          <v-card-text>
            <v-color-picker
              show-swatches
              mode="hexa"
              v-model="colorValue"
            ></v-color-picker>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="setColor" color="success">{{
              translate("common.ok")
            }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>
    </v-col>
    <v-col cols="12">
      <p class="caption">{{ translate("inputColor.caption") }}</p>
    </v-col>
  </v-row>
</template>

<script>
import lightOrDark from "@/mixins/light-or-dark-color";
import hexToRgbA from "@/mixins/hex-to-rgba.js";

export default {
  name: "input-color",
  mixins: [lightOrDark, hexToRgbA],
  props: {
    value: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      colorValue: this.value ? this.value : "#00000000",
      menu: false,
      isDark: this.value
        ? !this.lightOrDark(this.hexToRgbA(this.value))
        : false,
    };
  },
  methods: {
    setColor() {
      this.menu = false;
    },
  },
  watch: {
    value() {
      this.colorValue = this.value ? this.value : "#00000000";
      this.isDark = this.value
        ? !this.lightOrDark(this.hexToRgbA(this.value))
        : false;
    },
    colorValue() {
      if (
        this.value &&
        this.value.toUpperCase() === this.colorValue.toUpperCase()
      )
        return;
      this.$emit("input", this.colorValue);
      this.isDark = !this.lightOrDark(this.hexToRgbA(this.colorValue));
    },
  },
};
</script>

<style>
</style>
