
<template>
  <v-row>
    <v-col cols="12">
      <h2>{{ title }}</h2>
    </v-col>
    <v-col cols="12">
      <v-tabs>
        <v-tab
          class="text-uppercase"
          v-for="(module, index) in modules"
          :key="'tab-' + index"
        >
          {{ moduleTitle(module) }}
        </v-tab>
        <v-tab-item
          v-for="(module, index) in modules"
          :key="'item-' + index"
          eager
        >
          <component
            v-bind:is="module.component"
            :type="module.type"
          ></component>
        </v-tab-item>
      </v-tabs>
    </v-col>
  </v-row>
</template>

<script>
import StepperBus from "@/components/step/stepper-bus";
import ModulesBus from "./modules/modules-bus";
import Modules from "@/constants/modules-constants";
import Tracky from "./modules/tracky";
import ThemeVuetify from "./modules/theme-vuetify";

export default {
  name: "modules",
  components: {
    Tracky,
    ThemeVuetify,
  },
  props: {
    step: {
      type: Number,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.validStep(this.step);
    });
    StepperBus.$on("next-step", this.validStep);
  },
  computed: {
    modules() {
      return Modules.filter((m) => m.isVisible);
    },
  },
  methods: {
    validStep(step) {
      if (step !== this.step) return;
      ModulesBus.$emit("valid-modules");
    },
    moduleTitle(module) {
      return module.type
        ? this.translate(`${module.type}.title`)
        : this.translate(`${module.component}.title`);
    },
  },
};
</script>
