<template>
  <v-container fluid>
    <v-row>
      <v-col cols="10">
        <slot name="title"></slot>
      </v-col>
      <v-col cols="2"> <language-selector></language-selector> </v-col>
    </v-row>
    <slot name="content"></slot>
  </v-container>
</template>

<script>
import LanguageSelector from "@/components/language-selector";

export default {
  name: "main-layout",
  components: {
    LanguageSelector,
  },
};
</script>

<style>
</style>
