<template>
  <v-row>
    <v-col cols="12">
      <h2>{{ title }}</h2>
    </v-col>
    <v-col cols="12" md="4">
      <v-tabs vertical>
        <v-tab class="vertical-label text-uppercase">
          {{ translate("themePicker.themeAndColorChoice") }}
        </v-tab>
        <v-tab class="vertical-label text-uppercase">
          {{ translate("themePicker.typoAndBackgroundChoice") }}
        </v-tab>
        <v-tab class="vertical-label text-uppercase">
          {{ translate("themePicker.headerAndFooterChoice") }}
        </v-tab>
        <v-tab-item>
          <v-col cols="12">
            <h3 class="h3">{{ translate("themePicker.themeLabel") }}</h3>
            <v-btn-toggle v-model="themeSelected" mandatory>
              <v-btn text v-for="theme in themes" :key="theme.name">{{
                theme.name.toUpperCase()
              }}</v-btn>
            </v-btn-toggle>
          </v-col>
          <v-col cols="12">
            <h3>{{ translate("themePicker.colorLabel") }}</h3>
            <v-select
              hide-selected
              persistent-hint
              :items="variations"
              item-text="name"
              :hint="translate('themePicker.colorHint')"
              :placeholder="translate('common.select')"
              v-model="variationColorSelected"
              return-object
            ></v-select>
          </v-col>
          <v-col cols="12" v-if="variationColor">
            <input-color-group
              v-model="variationColor"
              translate-field="themePicker"
            />
          </v-col>
        </v-tab-item>
        <v-tab-item>
          <v-col cols="12">
            <h3>{{ translate("themePicker.typoLabel") }}</h3>
            <typo-picker :typos="typos" v-model="typo" />
          </v-col>
          <v-col cols="12">
            <h3>{{ translate("themePicker.backgroundLabel") }}</h3>
            <background-picker
              v-model="background"
              :label-image="translate('themePicker.backgroundImage')"
              :label-color="translate('themePicker.backgroundColor')"
              show-color
            />
          </v-col>
        </v-tab-item>
        <v-tab-item>
          <v-col cols="12">
            <h3>{{ translate("themePicker.headerLabel") }}</h3>
            <background-picker
              v-model="header"
              :label-position="translate('themePicker.headerPositionLabel')"
              :label-image="headerImageLabel"
              :label-color="translate('themePicker.headerColorLabel')"
              :label-size="translate('themePicker.headerSizeLabel')"
              :hint="translate('themePicker.headerFooterHint')"
              show-position
              show-color
              show-size
            />
            <h3>{{ translate("themePicker.footerLabel") }}</h3>
            <background-picker
              v-model="footer"
              :label-position="translate('themePicker.footerPositionLabel')"
              :label-image="footerImageLabel"
              :label-hidden="translate('themePicker.footerHiddenLabel')"
              :label-color="translate('themePicker.footerColorLabel')"
              :label-size="translate('themePicker.footerSizeLabel')"
              :hint="translate('themePicker.headerFooterHint')"
              show-hidden
              show-color
              show-position
              show-size
            />
          </v-col>
        </v-tab-item>
      </v-tabs>
    </v-col>
    <v-col cols="12" md="8">
      <demo
        :css-url="demoCss"
        :overlay="needRefreshDemo"
        @get-demo-css="getCss"
        class="theme-picker-demo"
      ></demo>
    </v-col>
  </v-row>
</template>

<script>
import variationColor from "@/mixins/variation-color";
import StepperBus from "@/components/step/stepper-bus";
import InputColorGroup from "@/components/input-color-group";
import Demo from "@/components/demo";
import ApiInstance from "@/api/api";
import themePicker from "@/constants/theme-picker-constants";
import BackgroundPicker from "@/components/background-picker.vue";
import TypoPicker from "@/components/typo-picker.vue";

export default {
  name: "theme-picker",
  mixins: [variationColor],
  components: {
    InputColorGroup,
    Demo,
    BackgroundPicker,
    TypoPicker,
  },
  props: {
    step: {
      type: Number,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    themeSelected: 0,
    variationColor: null,
    variationColorSelected: null,
    header: {
      image: null,
      position: null,
      hidden: false,
      color: null,
      size: 100,
    },
    footer: {
      image: null,
      position: null,
      hidden: true,
      color: null,
      size: 100,
    },
    typo: {
      font: null,
      size: null,
      color: null,
    },
    background: {
      image: null,
      position: null,
      hidden: false,
      color: null,
    },
    demoCss: null,
    needRefreshDemo: true,
    firstLoad: true,
    needRefreshDemoTimer: null,
    durationTimeForCustomCss: 15 * 60000, // 15 minutes
  }),
  mounted() {
    this.$nextTick(() => {
      this.setTheme();
      this.setHeader();
      this.setFooter();
      this.setVariationColor();
      this.setTypo();
      this.setBackground();
      this.validStep(this.step);
    });
    StepperBus.$on("next-step", this.validStep);
  },
  computed: {
    themes() {
      return themePicker.themes;
    },
    variations() {
      return [...this.themes[this.themeSelected].variations];
    },
    typos() {
      return { ...this.themes[this.themeSelected].typos };
    },
    headerImageLabel() {
      return `${this.translate("themePicker.headerImageLabel")} ${
        this.themes[this.themeSelected].idealHeaderWidth
      } / ${this.themes[this.themeSelected].idealHeaderHeight}`;
    },
    footerImageLabel() {
      return `${this.translate("themePicker.footerImageLabel")} ${
        this.themes[this.themeSelected].idealFooterWidth
      } / ${this.themes[this.themeSelected].idealFooterHeight}`;
    },
    elements() {
      return [this.typo, this.background, this.header, this.footer];
    },
  },
  methods: {
    setHeader() {
      if (this.$store.state.header) this.header = this.$store.state.header;
    },
    setFooter() {
      if (this.$store.state.footer) this.footer = this.$store.state.footer;
    },
    setTheme() {
      this.themeSelected = this.$store.state.theme
        ? this.themes.findIndex((t) => t.name === this.$store.state.theme)
        : 0;
    },
    setVariationColor() {
      if (this.$store.state.variationColor)
        this.variationColorSelected = this.$store.state.variationColor;
      else
        this.variationColorSelected =
          this.themes[this.themeSelected].variations[0];
      this.variationColor = this.variationColorSelected;
    },
    setTypo() {
      if (this.$store.state.typo) this.typo = this.$store.state.typo;
      else
        this.typo = {
          font: this.themes[this.themeSelected].typos.fonts[0],
          size: this.themes[this.themeSelected].typos.size,
          color: this.themes[this.themeSelected].typos.color,
        };
    },
    setBackground() {
      if (this.$store.state.background)
        this.background = this.$store.state.background;
      else
        this.background.color = this.themes[this.themeSelected].backgroundColor;
    },
    validStep(step) {
      if (step !== this.step) return;
      this.$store.commit("setVariationColor", this.variationColor);
      this.$store.commit("setTheme", this.themes[this.themeSelected].name);
      this.$store.commit("setHeader", this.header);
      this.$store.commit("setFooter", this.footer);
      this.$store.commit("setTypo", this.typo);
      this.$store.commit("setBackground", this.background);
    },
    async getCss() {
      this.needRefreshDemo = false;
      const result = await ApiInstance.createCustomCss({
        theme: this.themes[this.themeSelected].name,
        header: this.header,
        footer: this.footer,
        variationColor: this.variationColorToStr(this.variationColor.colors),
        typo: this.typo,
        background: this.background,
      });
      const data = result.data;
      if (!data || data.status !== 200) throw Error(data.errors[0]);
      clearTimeout(this.needRefreshDemoTimer);
      this.demoCss = ApiInstance.getCustomCssUrl(data.id);
      this.needRefreshDemoTimer = setTimeout(() => {
        this.needRefreshDemo = true;
      }, this.durationTimeForCustomCss);
    },
  },
  watch: {
    themeSelected() {
      this.variationColor = this.themes[this.themeSelected].variations[0];
      this.typo = {
        font: this.themes[this.themeSelected].typos.fonts[0],
        size: this.themes[this.themeSelected].typos.size,
        color: this.themes[this.themeSelected].typos.color,
      };
      this.background = {
        color: this.themes[this.themeSelected].backgroundColor,
        image: this.background.image,
      };
    },
    variationColorSelected() {
      this.variationColor = this.variationColorSelected;
      this.needRefreshDemo = true;
    },
    variationColor() {
      if (
        this.variationColorSelected.name === this.variationColor.name &&
        this.variationColor.name != "custom" &&
        this.variationColorToStr(this.variationColorSelected.colors) ===
          this.variationColorToStr(this.variationColor.colors)
      )
        return;
      this.variations[this.variations.length - 1].colors =
        this.variationColor.colors;
      this.variations[this.variations.length - 1].disabled = false;
      this.variationColor = this.variations[this.variations.length - 1];
      this.needRefreshDemo = true;
      this.variationColorSelected = this.variationColor;
    },
    elements() {
      this.needRefreshDemo = true;
    },
  },
};
</script>

<style scoped>
.v-tabs--vertical > .v-tabs-bar .v-tab.vertical-label {
  writing-mode: vertical-rl;
  height: initial;
  padding: 16px 0;
}

.theme-picker-demo {
  min-height: 1008px;
}
</style>
