<template>
  <v-select
    dense
    solo
    :items="langs"
    class="language-selector"
    v-model="langSelected"
  >
    <template v-slot:item="{ item }">
      <span :class="'flag-' + item"></span>
    </template>
    <template v-slot:selection="{ item }">
      <span :class="'flag-' + item"></span>
    </template>
  </v-select>
</template>

<script>
export default {
  name: "language-selector",
  data: () => ({
    langSelected: null,
  }),
  computed: {
    langs() {
      return Object.keys(this.$vuetify.lang.locales);
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.langSelected = this.$vuetify.lang.current;
    });
  },
  watch: {
    langSelected(val) {
      this.$vuetify.lang.current = val;
    },
  },
};
</script>

<style>
.language-selector {
  float: right;
  width: 80px;
}

[class^="flag-"] {
  width: 30px;
  height: 30px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 30px 30px;
}

.flag-fr {
  background-image: url(data:image/svg+xml;utf8,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20viewBox%3D%220%200%20900%20600%22%3E%3Crect%20width%3D%22900%22%20height%3D%22600%22%20fill%3D%22%23ED2939%22/%3E%3Crect%20width%3D%22600%22%20height%3D%22600%22%20fill%3D%22%23fff%22/%3E%3Crect%20width%3D%22300%22%20height%3D%22600%22%20fill%3D%22%23002395%22/%3E%3C/svg%3E);
}
.flag-en {
  background-image: url(data:image/svg+xml;utf8,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20viewBox%3D%220%200%2060%2030%22%3E%3CclipPath%20id%3D%22a%22%3E%3Cpath%20d%3D%22M0%200v30h60V0z%22/%3E%3C/clipPath%3E%3CclipPath%20id%3D%22b%22%3E%3Cpath%20d%3D%22M30%2015h30v15zv15H0zH0V0zV0h30z%22/%3E%3C/clipPath%3E%3Cg%20clip-path%3D%22url%28%23a%29%22%3E%3Cpath%20d%3D%22M0%200v30h60V0z%22%20fill%3D%22%23012169%22/%3E%3Cpath%20d%3D%22M0%200l60%2030m0-30L0%2030%22%20stroke%3D%22%23fff%22%20stroke-width%3D%226%22/%3E%3Cpath%20d%3D%22M0%200l60%2030m0-30L0%2030%22%20clip-path%3D%22url%28%23b%29%22%20stroke%3D%22%23C8102E%22%20stroke-width%3D%224%22/%3E%3Cpath%20d%3D%22M30%200v30M0%2015h60%22%20stroke%3D%22%23fff%22%20stroke-width%3D%2210%22/%3E%3Cpath%20d%3D%22M30%200v30M0%2015h60%22%20stroke%3D%22%23C8102E%22%20stroke-width%3D%226%22/%3E%3C/g%3E%3C/svg%3E);
}
</style>
