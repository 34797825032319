export default {
    methods: {
        hexToRgbA(hex) {
            var c;
            var a = 1;
            if (hex.length === 9) {
                a = parseInt(hex.substring(7), 16) / 255;
                hex = hex.substring(0, 7);
            }
            if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
                c = hex.substring(1).split('');
                if (c.length == 3) {
                    c = [c[0], c[0], c[1], c[1], c[2], c[2]];
                }
                c = '0x' + c.join('');
                return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',' + a + ')';
            }
            throw new Error('Bad Hex');
        },
    },
};
