const modules = [
    {
        component: 'tracky',
        isVisible: true,
    },
    {
        component: 'theme-vuetify',
        type: 'ecommerce',
        isVisible: true,
    },
    {
        component: 'theme-vuetify',
        type: 'rounds',
        isVisible: true,
    },
];

export default modules;
