const themeVuetify = {
    ecommerce: {
        isActive: false,
        colors: {
            primary: '#008272',
            secondary: '#008272',
            accent: '#51b8a6',
            error: '#FF5252',
            info: '#2196F3',
            success: '#4CAF50',
            warning: '#FFC107',
            eurekaPrice: '#4CAF50',
            eurekaSelectedItem: '#4CAF50',
            eurekaCardTitle: '#FFFFFF',
            eurekaTrash: '#FF5252',
            eurekaLogoFont: '#008272',
            eurekaCo2Positive: '#ff9800',
            eurekaCo2Negative: '#4CAF50',
            text__addressInfoStepTab: '#000000',
        },
    },
    rounds: {
        isActive: false,
        colors: {
            primary: '#ff6f00',
            secondary: '#008272',
            accent: '#616161',
            error: '#D50000',
            info: '#0D47A1',
            success: '#388E3C',
            warning: '#FFC107',
        },
    },
};

export default themeVuetify;
