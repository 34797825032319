export default {
    methods: {
        variationColorToStr(variationColors) {
            return (
                Object.entries(variationColors)
                    .map((c) => `@${c[0].toKebabCase()}:${c[1]}`)
                    .join(';\r\n') + ';\r\n'
            );
        },
        arrayToVariationColors(colors) {
            const obj = {};
            colors.forEach((color) => {
                obj[color.Name] = color.Value;
            });
            return obj;
        },
        strToVariationColor(str, defaultColors) {
            const variations = str
                .split('\r\n')
                .map((v) =>
                    v
                        .kebabCaseToCamelCase()
                        .substr(1)
                        .replace(';', ''),
                )
                .filter((v) => v)
                .map((v) => ({ name: v.split(':')[0], value: v.split(':')[1] }));
            variations.forEach((variation) => {
                defaultColors[variation.name] = variation.value;
            });
            return defaultColors;
        },
    },
};
