<template>
  <v-col cols="12">
    <v-checkbox v-model="isActive" :label="translate(`${type}.isActive`)">
    </v-checkbox>
    <v-row v-if="isActive">
      <v-col cols="12" v-if="variationColor">
        <h3 class="h3">{{ translate("modules.colorChoice") }}</h3>
        <input-color-group
          v-model="variationColor"
          :translate-field="type"
          :md="6"
        />
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import ModulesBus from "./modules-bus";
import ThemeVuetify from "@/constants/theme-vuetify-constants";
import InputColorGroup from "@/components/input-color-group";
import variationColor from "@/mixins/variation-color";

export default {
  name: "theme-vuetify",
  components: {
    InputColorGroup,
  },
  mixins: [variationColor],
  props: {
    type: {
      type: String,
      required: true,
    },
  },
  data() {
    return Object.assign({}, ThemeVuetify[this.type]);
  },
  mounted() {
    this.$nextTick(() => {
      this.setModule();
    });
    ModulesBus.$on("valid-modules", this.validModule);
  },
  computed: {
    variationColor: {
      get() {
        return {
          colors: this.colors,
        };
      },
      set(value) {
        this.colors = value.colors;
      },
    },
  },
  methods: {
    validModule() {
      this.$store.commit("setModule", {
        type: this.type,
        isActive: this.isActive,
        colors: this.colors,
      });
    },
    setModule() {
      const module = this.$store.state.modules.find(
        (m) => m.type === this.type
      );
      if (!module) return;
      this.colors = module.colors;
      this.isActive = module.isActive;
    },
  },
};
</script>

<style>
</style>
