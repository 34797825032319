const stepper = {
    steps: [
        {
            component: 'clientInformation',
            step: 0,
            isValid: false,
            description: false,
            isOptional: false,
        },
        {
            component: 'themePicker',
            step: 1,
            isValid: true,
            description: false,
            isOptional: true,
        },
        {
            component: 'pictosPicker',
            step: 2,
            isValid: true,
            description: true,
            type: 'packageFamily',
            parentType: null,
            isOptional: true,
        },
        {
            component: 'pictosPicker',
            step: 3,
            isValid: true,
            description: false,
            type: 'prestaFamily',
            parentType: null,
            isOptional: true,
        },
        {
            component: 'pictosPicker',
            step: 4,
            isValid: true,
            description: false,
            type: 'presta',
            parentType: 'prestaFamily',
            isOptional: true,
        },
        {
            component: 'modules',
            step: 5,
            isValid: true,
            description: false,
            isOptional: true,
        },
        {
            component: 'sendMail',
            step: 6,
            isValid: true,
            description: false,
            isOptional: false,
        },
    ],
};

export default stepper;
