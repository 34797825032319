<template>
  <main-layout>
    <template v-slot:title>
      <h1>{{ translate("update.title") }}</h1>
      <h2>{{ translate("update.subTitle") }}</h2>
    </template>
    <template v-slot:content>
      <upload-config />
    </template>
  </main-layout>
</template>

<script>
import UploadConfig from "@/components/upload-config.vue";
import MainLayout from "./layouts/main";

export default {
  name: "update",
  components: {
    MainLayout,
    UploadConfig,
  },
};
</script>

<style>
</style>
