import fr from 'vuetify/lib/locale/fr';

export default {
    ...fr,
    create: {
        title: 'Demande de personnalisation Dispatch Web',
        subTitle: 'Merci de choisir les éléments permettant de personnaliser votre espace DispatchWeb',
    },
    update: {
        title: "Mise à jour d'un CSS client",
        subTitle: 'Veuiller selectionner le fichier "config.json"',
    },
    uploadConfig: {
        file: 'Fichier',
    },
    clientInformation: {
        title: 'Informations clients',
        clientNameLabel: 'Nom du client (Code Licence Dispatch)',
        clientNamePlaceholder: 'Société de transport',
        ReferentLabel: 'Référent Dispatch Web chez le client',
        ReferentPlaceholder: 'Nom / numéro de téléphone / email',
    },
    pictosPicker: {
        addPicto: 'Ajouter un picto',
        suppPicto: 'Supprimer ce picto?',
        newPicto: 'Nouveau picto',
        parentPictos: 'Pictos',
        headers: {
            pictos: 'Pictos',
            codes: 'CODES des entitées DISPATCH associées',
            actions: 'Actions',
        },
        types: {
            packageFamily: {
                title: 'Famille de colis',
                description:
                    "Les familles de colis sont accessibles dans Dispatch Web en saisie de mission, elles permettent de guider l'utilisateur afin de choisir au mieux son colisage",
            },
            prestaFamily: {
                title: 'Famille de prestation',
            },
            presta: {
                title: 'Prestation',
            },
        },
    },
    modules: {
        title: 'Configuration des modules',
        colorChoice: 'Couleurs',
    },
    ecommerce: {
        title: 'E-Commerce',
        isActive: "Configurer l'E-commerce ?",
        colors: {
            primary: 'Couleur primaire',
            secondary: 'Couleur secondaire',
            accent: "Couleur d'accentuation",
            error: "Couleur en cas d'erreur",
            info: "Couleur d'information",
            success: 'Couleur en cas succés',
            warning: "Couleur d'alerte",
            eurekaPrice: 'Couleur du prix',
            eurekaSelectedItem: "Couleur d'élement sélectionné",
            eurekaCardTitle: 'Couleur du titre de chaque étape',
            eurekaTrash: 'Couleur de la poubelle',
            eurekaLogoFont: 'Couleur du texte du logo',
            eurekaCo2Positive: 'Couleur du CO2 positif',
            eurekaCo2Negative: 'Couleur du CO2 négatif',
            text__addressInfoStepTab: "Couleur du texte de l'addresse",
        },
    },
    rounds: {
        title: 'Visu des tournées',
        isActive: 'Configurer la visu des tournées ?',
        colors: {
            primary: 'Couleur primaire',
            secondary: 'Couleur secondaire',
            accent: "Couleur d'accentuation",
            error: "Couleur en cas d'erreur",
            info: "Couleur d'information",
            success: 'Couleur en cas succés',
            warning: "Couleur d'alerte",
        },
    },
    tracky: {
        title: 'Tracky',
        isActive: 'Configurer Tracky ?',
        headerChoice: 'Entête',
        colors: {
            mainColor: 'Couleur principale',
            panelsIconsColor: 'Couleur des icones',
            headerBgColor: "Couleur de fond de l'entête",
        },
        applyThemeColor: 'Appliquer le thème de couleurs',
    },
    themePicker: {
        title: 'Configuration du thème',
        themeAndColorChoice: 'Thème & couleurs',
        themeLabel: 'Choix du thème',
        headerAndFooterChoice: 'Entête & pied de page',
        typoAndBackgroundChoice: 'Police & fond du site',
        typoLabel: 'Police',
        backgroundLabel: 'Fond du site',
        backgroundColor: 'Couleur de fond',
        backgroundImage: 'Image de fond',
        colorHint: 'exemples de couleurs prédéfinies pouvant être appliquées sur Dispatc hWeb',
        colorLabel: 'Variations de couleurs',
        headerFooterHint: "Si aucune image est sélectionnée, l'image Dispatch par défaut sera affichée",
        headerLabel: 'Entête',
        headerImageLabel: 'Taille idéale:',
        headerPositionLabel: "Position de l'entête",
        headerColorLabel: "Couleur de fond de l'entête",
        headerSizeLabel: "Taile de l'image de l'entête",
        footerLabel: 'Pied de page',
        footerImageLabel: 'Taille idéale:',
        footerPositionLabel: 'Position du pied de page',
        footerColorLabel: 'Couleur de fond du pied de page',
        footerHiddenLabel: 'Masquer le pied de page',
        footerSizeLabel: "Taile de l'image du pied de page",
        colors: {
            mainColor: 'Couleur principale',
            mainColorRegular: 'Couleur principale',
            stepColorInactive: "Couleur d'étape inactive",
            searchColorRegular: 'Couleur de la recherche',
            payColorRegular: 'Couleur du paiement',
            stepColorActive: "Couleur d'étape active",
            stepColorTextActive: "Couleur du text de l'étape active",
            stepColorCircleActive: "Couleur du cercle de l'étape active",
            stepColorNumberActive: "Couleur du chiffre de l'étape active",
            tabColor: 'Couleur des onglets',
        },
    },
    sendMail: {
        title: 'Finalisation de la demande',
        comment: 'Commentaire',
        cssUrl: "Personnaliser l'url",
        cssUrlHint: 'exemple d\'url: "client" | "client/agence" | "client/donneur d\'ordre"',
        sendMailTitle: 'Envoie de la demande réussi',
        sendMailErrorTitle: "Erreur lors de l'envoie de de la demande",
        sendMailErrorMessage: "Veuillez contacter l'équipe de support ou réessayer plus tard",
        sendMailSuccessMessage: 'Souhaitez vous personnaliser un nouveau DispatchWeb ?',
    },
    stepper: {
        optional: 'Optionnel',
        prev: 'Etape précedente',
        next: 'Etape Suivante',
        sendRequest: 'Envoyer la demande',
    },
    inputColor: {
        caption: 'Cliquer sur le bouton pour selectionner une couleur',
    },
    demo: {
        refreshButton: 'Afficher le rendu du thème',
    },
    typoPicker: {
        fontLabel: 'Type de police',
        sizeLabel: 'Taile de police',
        colorLabel: 'Couleur de police',
    },
    pictoEditor: {
        tooltip:
            'Merci de respecter les majuscules/minuscules des codes de prestation.\nLes espaces dans les codes ne sont pas autorisés',
        pictoBank: 'Banque de picto',
        customization: 'Personnalisé',
        newPicto: 'Nouveau picto',
        editPicto: 'Édition du picto',
        customPicto: 'Picto personnaliser',
        customColor: 'Couleur',
        resetColors: 'Remettre les couleurs par défaut',
        pictos: {
            Other: 'Autre',
            Truck: 'Camion',
            Plane: 'Avion',
            Car: 'Voiture',
            Moto: 'Moto',
            Scooter: 'Scooter',
            Boat: 'Bateau',
            Handling: 'Manutention',
            Helicopter: 'Hélicoptère',
            Drone: 'Drone',
            Moving: 'Déménagement',
            Concierge: 'Conciergerie',
            Pickup: 'Camionette',
            Bus: 'Bus',
            Electric_vehicle: 'Véhicule électrique',
            Executive_vehicle: 'Véhicule de direction',
            Bike: 'Vélo',
            Vehicle_for_people_with_reduced_mobility: 'Véhicule pour personne à mobilité réduite',
            Vehicle_with_tailgate: 'Véhicule avec hayon',
            Ecological_vehicle: 'Véhicule écologique',
            Emergency: 'Urgence',
            Trike_bike: 'Triporteur',
            Moto_taxi: 'Moto taxi',
            Taxi: 'Taxi',
            Hotel_reservation: 'Réservation hôtel',
            Roller: 'Roller',
            Tractor_only: 'Tracteur seul',
            Truck_3_axles: 'Poids lourd 3 essieux',
            Truck__trailer: 'Poids lourd + remorque',
            Pedestrian: 'Piéton',
            Computer_failure: 'Panne informatique',
            Failure: 'Panne',
            Security: 'Sécurité',
            Van: 'Fourgon',
            Truck_2_trailers: 'Poids lourd 2 remorques',
            Truck_tanker: 'Poids lourd citerne',
            Truck_large_cab: 'Poids lourd grosse cabine',
            Low_trailer: 'Remorque basse',
            Truck_5_axles: 'Poids lourd 5 essieux',
            Truck_4_axles: 'Poids lourd 4 essieux',
            Truck_heavy_weight: 'Poids lourd plateau',
            Electric_vehicle_2: 'Véhicule électrique 2',
            Folds: 'Plis',
            Parcel: 'Colis',
            Bulky: 'Encombrant',
            Palette: 'Palette',
            Electronics: 'Electronique',
            Blood: 'Sang',
            Health: 'Santé',
            Group: 'Groupe',
            Reduced_mobility: 'Mobilé réduite',
            Person: 'Personne',
            Vegetal: 'Végétal',
            Food: 'Alimentaire',
            Luggage: 'Bagages',
            Gift: 'Cadeau',
            Chemical: 'Chimique',
            Waste: 'Déchet',
            Containers: 'Conteneurs',
            Mode: 'Mode',
            Cold: 'Froid',
            Event: 'Evénement',
            Luxury: 'Luxe',
            Art_Object: "Objet d'Art",
            Furniture: 'Mobilier',
            Hazardous_material: 'Matière dangereuse',
            Empty_container: 'Conteneur vide',
            Container_full: 'Conteneur plein',
            Tire: 'Pneu',
            Windshield: 'Parebrise',
        },
    },
    errors: {
        required: 'Requis',
        imageRules: "La taille de l'image doit être inférieure à 2 Mo",
        notNull: 'Ne peut pas être vide',
        spaceBetween: 'Contiens des espaces entre',
        alreadyExist: 'Existe déjà',
    },
    common: {
        ok: 'OK',
        cancel: 'Annuler',
        yes: 'Oui',
        no: 'Non',
        add: 'Ajouter',
        modify: 'Modifier',
        chooseImage: 'choisir une image',
        select: 'Selectionner',
    },
};
