<template>
  <div class="demo-container" :class="fullscreen ? 'fullscreen' : ''">
    <div class="iframe-container">
      <v-btn
        icon
        color="gray"
        large
        class="demo-toggle"
        v-if="cssUrl"
        @click="toggleFullScreen"
      >
        <v-icon v-if="!fullscreen">mdi-fullscreen</v-icon>
        <v-icon v-if="fullscreen">mdi-fullscreen-exit</v-icon>
      </v-btn>
      <iframe ref="demo" :src="demoUrl" @load="onLoad" v-if="cssUrl"></iframe>
      <v-overlay :absolute="absolute" :value="overlay">
        <v-btn color="success" @click="refresh">
          {{ translate("demo.refreshButton") }}
        </v-btn>
      </v-overlay>
      <v-overlay :value="loader" :absolute="absolute">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
    </div>
  </div>
</template>

<script>
export default {
  name: "demo",
  props: {
    host: {
      type: String,
      default: "https://dispatchweb.eureka-technology.fr",
    },
    login: {
      type: String,
      default: "css",
    },
    password: {
      type: String,
      default: "css",
    },
    license: {
      type: String,
      default: "FORMAPIWEB",
    },
    cssUrl: {
      type: String,
      default: null,
    },
    overlay: {
      type: Boolean,
    },
  },
  data: () => ({
    webManagerRequest: "/webmanager/Authentication/SignIn",
    absolute: true,
    loader: false,
    fullscreen: false,
  }),
  computed: {
    demoUrl() {
      return `${this.host}${
        this.webManagerRequest
      }?clientURL=dispatchweb&forcedCssPath=${encodeURIComponent(
        this.cssUrl
      )}&DisableSSL=1&license=${this.license}&login=${this.login}&password=${
        this.password
      }`;
    },
  },
  methods: {
    refresh() {
      this.loader = true;
      this.$emit("get-demo-css");
    },
    onLoad() {
      this.loader = false;
    },
    toggleFullScreen() {
      this.fullscreen = !this.fullscreen;
    },
  },
};
</script>
<style>
.demo-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.demo-container.fullscreen {
  position: absolute;
  top: 0;
  left: 0;
}

.iframe-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 95%;
}

.iframe-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.demo-toggle {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
}
</style>
