<template>
  <v-row>
    <v-col cols="12">
      <h2>{{ title }}</h2>
    </v-col>
    <v-col cols="12">
      <v-textarea
        :label="translate('sendMail.comment')"
        no-resize
        rows="10"
        v-model="comment"
      ></v-textarea>
    </v-col>
    <v-col cols="12">
      <v-text-field
        :label="translate('sendMail.cssUrl')"
        v-model="cssUrl"
        :prefix="baseUrl"
        :hint="translate('sendMail.cssUrlHint')"
        persistent-hint
      />
    </v-col>
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-dialog v-model="showDialog" persistent max-width="290">
      <v-card v-if="sendMailSuccess">
        <v-card-title class="text-h5">
          {{ translate("sendMail.sendMailTitle") }}
        </v-card-title>
        <v-card-text>
          {{ translate("sendMail.sendMailSuccessMessage") }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="reloadPage()">
            {{ translate("common.yes") }}
          </v-btn>
          <v-btn color="green darken-1" text @click="showDialog = false">
            {{ translate("common.no") }}
          </v-btn>
        </v-card-actions>
      </v-card>
      <v-card v-else>
        <v-card-title class="text-h5">
          {{ translate("sendMail.sendMailErrorTitle") }}
        </v-card-title>
        <v-card-text>
          {{ translate("sendMail.sendMailErrorMessage") }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="showDialog = false">
            {{ translate("common.ok") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import SendMail from "@/constants/send-mail-constants";
import variationColor from "@/mixins/variation-color";
import StepperBus from "@/components/step/stepper-bus";
import ApiInstance from "@/api/api";

export default {
  name: "send-mail",
  mixins: [variationColor],
  props: {
    step: {
      type: Number,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      comment: null,
      cssUrl: null,
      loading: false,
      sendMailSuccess: true,
      showDialog: false,
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.comment = this.$store.state.comment;
      this.cssUrl = this.$store.state.cssUrl;
      this.validStep(this.step);
    });
    StepperBus.$on("next-step", this.validStep);
    StepperBus.$on("send-mail", this.sendMail);
  },
  methods: {
    validStep(step) {
      const now = new Date();
      if (step !== this.step) return;
      this.$store.commit("setComment", this.comment);
      this.$store.commit("setCssUrl", this.cssUrl);
      if (!this.$store.state.createDate)
        this.$store.commit("setCreateDate", now.toDateString());
      else {
        this.$store.commit("setLastUpdateDate", now.toDateString());
      }
    },
    async sendMail() {
      this.validStep(this.step);
      this.loading = true;
      this.sendMailSuccess = true;
      const config = {
        refClient: this.$store.state.refClient,
        licenseCode: this.$store.state.licenseCode,
        theme: this.$store.state.theme,
        variationColor: this.variationColorToStr(
          this.$store.state.variationColor.colors
        ),
        header: this.$store.state.header,
        footer: this.$store.state.footer,
        typo: this.$store.state.typo,
        background: this.$store.state.background,
        pictos: this.$store.state.pictos,
        comment: this.$store.state.comment,
        cssUrl: this.$store.state.cssUrl,
        lastUpdateDate: this.$store.state.lastUpdateDate,
        createDate: this.$store.state.createDate,
        tracky: {
          isActive: this.$store.state.tracky.isActive,
          colors: this.variationColorToStr(this.$store.state.tracky.colors),
          image: this.$store.state.tracky.image,
        },
        modules: this.$store.state.modules.map((m) => ({
          type: m.type,
          isActive: m.isActive,
          colors: JSON.stringify(m.colors),
        })),
      };
      try {
        const result = await ApiInstance.createZip(config);
        const data = result.data;
        if (!data || data.status !== 200) throw Error(data.errors[0]);
        const zipData = await ApiInstance.getZip(data.id);
        if (!zipData || zipData.status !== 200) throw Error(zipData.errors[0]);

        const mailData = {
          client: config.licenseCode,
          contact: config.refClient,
          cssUrl: config.cssUrl,
          additionalComment: config.comment ?? "",
          zipName: `${config.licenseCode}-${config.lastUpdateDate.replaceAll(
            " ",
            "-"
          )}.zip`,
          zip: zipData.data,
        };
        const sendMailResult = await ApiInstance.sendMail(mailData);
        if (sendMailResult.message) throw Error(sendMailResult.message);
      } catch (error) {
        this.sendMailSuccess = false;
        console.error(error);
      } finally {
        this.loading = false;
        this.showDialog = true;
      }
    },
    reloadPage() {
      window.location.reload(false);
    },
  },
  computed: {
    licenseCode() {
      return this.$store.state.licenseCode;
    },
    baseUrl() {
      return SendMail.dispatchWebBaseUrl;
    },
  },
  watch: {
    licenseCode() {
      this.cssUrl = `${this.$store.state.licenseCode}`;
    },
  },
};
</script>

<style>
</style>
