const tracky = {
    isActive: false,
    colors: {
        mainColor: '#000000',
        panelsIconsColor: '#FFFFFF',
        headerBgColor: '#FFFFFF',
    },
    image: null,
};

export default tracky;
