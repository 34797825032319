<template>
  <div class="background-picker">
    <h4 v-if="labelImage">{{ labelImage }}</h4>
    <v-checkbox
      v-if="showHidden"
      v-model="hidden"
      :label="labelHidden"
    ></v-checkbox>
    <div v-if="hidden === false">
      <v-file-input
        v-model="model"
        :rules="imageRules"
        accept="image/png, image/jpeg, image/bmp"
        :placeholder="translate('common.chooseImage')"
        prepend-icon="mdi-image"
        persistent-hint
        :hint="hint"
        @change="changeImage"
        v-if="!hasImage"
      ></v-file-input>
      <v-row v-else>
        <v-col cols="12" align-self="center" class="background-picker-result">
          <img :src="this.image" class="background-picker-img" />
          <div class="background-picker-img-btn">
            <v-btn fab color="black" dark x-small @click="resetImage">
              <v-icon>mdi-close-thick</v-icon>
            </v-btn>
          </div>
        </v-col>
      </v-row>
      <input-color
        v-if="showColor"
        v-model="color"
        :label="labelColor"
        class="background-color-picker"
      ></input-color>
      <h4 v-if="showPosition">{{ labelPosition }}</h4>
      <v-btn-toggle v-if="showPosition" v-model="positionIndex" mandatory>
        <div class="position-container">
          <v-btn icon><v-icon>mdi-arrow-top-left-thick</v-icon></v-btn>
          <v-btn icon><v-icon>mdi-arrow-up-thick</v-icon></v-btn>
          <v-btn icon><v-icon>mdi-arrow-top-right-thick</v-icon></v-btn>
          <v-btn icon><v-icon>mdi-arrow-left-thick</v-icon></v-btn>
          <v-btn icon><v-icon>mdi-close-thick</v-icon></v-btn>
          <v-btn icon><v-icon>mdi-arrow-right-thick</v-icon></v-btn>
          <v-btn icon><v-icon>mdi-arrow-bottom-left-thick</v-icon></v-btn>
          <v-btn icon><v-icon>mdi-arrow-down-thick</v-icon></v-btn>
          <v-btn icon><v-icon>mdi-arrow-bottom-right-thick</v-icon></v-btn>
        </div>
      </v-btn-toggle>
      <h4>{{ labelSize }}</h4>
      <v-slider
        v-if="showSize"
        v-model="size"
        class="align-center"
        :max="100"
        :min="0"
        hide-details
        thumb-label
      >
        <template v-slot:append>
          <v-text-field
            v-model="size"
            class="mt-0 pt-0"
            hide-details
            single-line
            type="number"
            style="width: 60px"
          ></v-text-field>
        </template>
      </v-slider>
    </div>
  </div>
</template>

<script>
import ImageRules from "@/mixins/image-rules";
import Base64 from "@/mixins/base-64";
import InputColor from "@/components/input-color";

export default {
  name: "background-picker",
  components: { InputColor },
  mixins: [ImageRules, Base64],
  props: {
    labelPosition: {
      type: String,
      default: null,
    },
    labelHidden: {
      type: String,
      default: null,
    },
    labelImage: {
      type: String,
      default: null,
    },
    labelColor: {
      type: String,
      default: null,
    },
    labelSize: {
      type: String,
      default: null,
    },
    value: {
      type: Object,
      default: null,
    },
    hint: {
      type: String,
      default: null,
    },
    showHidden: {
      type: Boolean,
      default: false,
    },
    showPosition: {
      type: Boolean,
      default: false,
    },
    showColor: {
      type: Boolean,
      default: false,
    },
    showSize: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const positionValues = [
      "top left",
      "top center",
      "top right",
      "center left",
      "center center",
      "center right",
      "bottom left",
      "bottom center",
      "bottom right",
    ];
    return {
      positionIndex:
        this.value && this.value.position
          ? positionValues.indexOf(this.value.position)
          : 4,
      positionValues: positionValues,
      image: this.value ? this.value.image : null,
      hidden: this.value ? this.value.hidden : false,
      color: this.value ? this.value.color : null,
      size: this.value ? this.value.size : 100,
      model: null,
    };
  },
  computed: {
    elements() {
      return [
        this.positionIndex,
        this.hidden,
        this.color,
        this.size,
        this.image,
      ];
    },
    hasImage() {
      return this.image;
    },
  },
  watch: {
    elements() {
      this.update();
    },
  },
  methods: {
    changeImage(file) {
      this.toBase64Img(file, (result) => {
        this.image = result;
        this.update();
      });
    },
    update() {
      this.$emit("input", {
        image: this.image,
        position:
          this.positionIndex !== null && this.positionIndex !== undefined
            ? this.positionValues[this.positionIndex]
            : null,
        hidden: this.hidden,
        color: this.color,
        size: this.size,
      });
    },
    resetImage() {
      this.image = null;
      this.model = null;
    },
  },
};
</script>

<style>
.background-picker {
  padding-top: 15px;
}

.position-container {
  display: grid;
  grid-template-columns: auto auto auto;
  grid-gap: 5px;
  padding: 5px;
  margin-left: auto;
  margin-right: auto;
}

.v-item--active.v-btn--active {
  background-color: #1976d2;
}

.background-color-picker {
  padding-top: 15px;
}

.background-picker-result {
  position: relative;
}

.background-picker-img {
  max-height: 50px;
  height: auto;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.background-picker-img-btn {
  position: absolute;
  top: 5px;
  right: 5px;
}
</style>
