<template>
  <v-app id="app">
    <router-view />
  </v-app>
</template>

 <script>
export default {
  name: "App",
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        document.title = `My DispatchWeb - ${to.meta.title}`;
      },
    },
  },
};
</script>
 <style>
</style>
