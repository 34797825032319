import axios from 'axios';

class Api {
    _baseApi = '/api/';
    _webhook =
        'https://prod-27.westeurope.logic.azure.com:443/workflows/6060b574bbbb4ec7b128f763d0160922/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=PoLCLUAlV6GiOGo74pNNeafvB24Me3ljN_LsD7mKq8Y';

    async getLessValidation(less) {
        const request = {
            Less: less,
        };
        return axios.post(`${this._baseApi}less/validation`, request);
    }

    async createCustomCss(cssRequest) {
        return axios.post(`${this._baseApi}less/custom-css`, cssRequest);
    }

    getCustomCssUrl(key) {
        return `${location.protocol}//${location.host}${this._baseApi}less/custom-css/${key}`;
    }

    async createZip(request) {
        return axios.post(`${this._baseApi}pack`, request);
    }

    async getZip(key) {
        return axios.get(`${this._baseApi}pack/${key}.zip`);
    }

    async sendMail(request) {
        return fetch(this._webhook, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            mode: 'no-cors',
            body: JSON.stringify(request),
        });
    }
}

const ApiInstance = new Api();
export default ApiInstance;
export { Api, ApiInstance };
