<template>
  <v-col cols="12">
    <v-checkbox v-model="isActive" :label="translate('tracky.isActive')">
    </v-checkbox>
    <v-row v-if="isActive">
      <v-col cols="12" md="8" v-if="variationColor">
        <h3 class="h3">{{ translate("modules.colorChoice") }}</h3>
        <input-color-group v-model="variationColor" translate-field="tracky" />
      </v-col>
      <v-col cols="12" md="8">
        <h3>{{ translate("tracky.headerChoice") }}</h3>
        <background-picker
          v-model="header"
          :label-image="headerImageLabel"
          :hint="translate('themePicker.headerFooterHint')"
        />
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import InputColorGroup from "@/components/input-color-group";
import BackgroundPicker from "@/components/background-picker";
import variationColor from "@/mixins/variation-color";
import ModulesBus from "./modules-bus";
import Tracky from "@/constants/tracky-constants";

export default {
  name: "tracky",
  components: { InputColorGroup, BackgroundPicker },
  mixins: [variationColor],
  data() {
    return Object.assign({}, Tracky);
  },
  mounted() {
    this.$nextTick(() => {
      this.setTracky();
    });
    ModulesBus.$on("valid-modules", this.validModule);
  },
  computed: {
    headerImageLabel() {
      return `${this.translate("themePicker.headerImageLabel")} 150px / 50px`;
    },
    variationColor: {
      get() {
        return {
          colors: this.colors,
        };
      },
      set(value) {
        this.colors = value.colors;
      },
    },
    header: {
      get() {
        return {
          image: this.image,
          hidden: false,
        };
      },
      set(value) {
        this.image = value.image;
      },
    },
  },
  methods: {
    validModule() {
      this.$store.commit("setTracky", {
        isActive: this.isActive,
        colors: this.colors,
        image: this.image,
      });
    },
    setTracky() {
      if (!this.$store.state.tracky) return;
      this.colors = this.$store.state.tracky.colors;
      this.isActive = this.$store.state.tracky.isActive;
      this.image = this.$store.state.tracky.image;
    },
  },
};
</script>

<style scoped>
.h3 {
  padding-bottom: 16px;
}

.background-picker {
  padding-top: 0;
}
</style>
