<template>
  <v-row>
    <v-col cols="12">
      <h4>{{ translate("typoPicker.fontLabel") }}</h4>
      <v-select
        hide-selected
        persistent-hint
        :items="fonts"
        item-text="name"
        :placeholder="translate('common.select')"
        v-model="font"
        return-object
      ></v-select>
      <h4>{{ translate("typoPicker.sizeLabel") }}</h4>
      <v-slider
        v-model="size"
        class="align-center"
        :max="maxFontSize"
        :min="minFontSize"
        hide-details
        thumb-label
      >
        <template v-slot:append>
          <v-text-field
            v-model="size"
            class="mt-0 pt-0"
            hide-details
            single-line
            type="number"
            style="width: 60px"
          ></v-text-field>
        </template>
      </v-slider>
    </v-col>
    <v-col cols="12">
      <input-color
        :label="translate('typoPicker.colorLabel')"
        v-model="color"
      ></input-color>
    </v-col>
  </v-row>
</template>

<script>
import InputColor from "@/components/input-color";

export default {
  name: "typo-picker",
  components: {
    InputColor,
  },
  props: {
    typos: {
      type: Object,
      default: null,
    },
    value: {
      type: Object,
      default: null,
    },
    colorLabel: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      font:
        this.value && this.value.font ? this.value.font : this.typos.fonts[0],
      size: this.value && this.value.size ? this.value.size : this.typos.size,
      color:
        this.value && this.value.color ? this.value.color : this.typos.color,
      minFontSize: 10,
      maxFontSize: 50,
    };
  },
  methods: {
    update() {
      this.$emit("input", {
        font: this.font,
        size: this.size,
        color: this.color,
      });
    },
  },
  computed: {
    fonts() {
      return this.typos ? this.typos.fonts : [];
    },
    elements() {
      return [this.font, this.size, this.color];
    },
  },
  watch: {
    value() {
      this.font = this.value ? this.value.font : this.typos.fonts[0];
      this.size = this.value ? this.value.size : this.typos.size;
      this.color = this.value ? this.value.color : this.typos.color;
    },
    elements() {
      this.update();
    },
  },
};
</script>

<style>
</style>
