String.prototype.formatColor = function() {
    let a = this;
    for (let k = 0; k < arguments.length; k++) {
        a = a.replaceAll('{color' + (k + 1) + '}', arguments[k]);
    }
    return a;
};
String.prototype.colorLuminance = function(lum) {
    let hex = this;
    hex = String(hex).replace(/[^0-9a-f]/gi, '');
    if (hex.length < 6) {
        hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }
    lum = lum || 0;
    let rgb = '#',
        c,
        i;
    for (i = 0; i < 3; i++) {
        c = parseInt(hex.substr(i * 2, 2), 16);
        c = Math.round(Math.min(Math.max(0, c + c * lum), 255)).toString(16);
        rgb += ('00' + c).substr(c.length);
    }
    return rgb;
};
String.prototype.toKebabCase = function() {
    let str = this;
    let kebab = '';
    [...str].forEach((c) => {
        if (c.match(/[A-Z]/g)) kebab += '-';
        kebab += c.toLocaleLowerCase();
    });
    return kebab;
};
String.prototype.decapitalize = function() {
    return `${this.charAt(0).toLowerCase()}${this.slice(1)}`;
};

String.prototype.kebabCaseToCamelCase = function() {
    let str = this;
    return str.replace(/-./g, (x) => x.toUpperCase()[1]);
};
