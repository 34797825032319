import 'es6-promise/auto';
import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';

export default new Vuex.Store({
    state: {
        refClient: null,
        licenseCode: null,
        theme: null,
        variationColor: null,
        header: null,
        footer: null,
        background: null,
        pictos: {
            packageFamily: null,
            prestaFamily: null,
            presta: null,
            satisfactionSurvey: null,
        },
        typo: null,
        comment: null,
        cssUrl: null,
        lastUpdateDate: null,
        createDate: null,
        tracky: null,
        modules: [],
    },
    mutations: {
        setRefClient: (state, refClient) => {
            state.refClient = refClient;
        },
        setLicenseCode: (state, licenseCode) => {
            state.licenseCode = licenseCode;
        },
        setTheme: (state, theme) => {
            state.theme = theme;
        },
        setVariationColor: (state, variationColor) => {
            state.variationColor = variationColor;
        },
        setHeader: (state, header) => {
            state.header = header;
        },
        setFooter: (state, footer) => {
            state.footer = footer;
        },
        setTypo: (state, typo) => {
            state.typo = typo;
        },
        setBackground: (state, background) => {
            state.background = background;
        },
        setPictos: (state, pictosByType) => {
            if (!state.pictos) state.pictos = {};
            state.pictos[pictosByType.type] = pictosByType.pictos;
        },
        setComment: (state, comment) => {
            state.comment = comment;
        },
        setCssUrl: (state, cssUrl) => {
            state.cssUrl = cssUrl;
        },
        setLastUpdateDate: (state, lastUpdateDate) => {
            state.lastUpdateDate = lastUpdateDate;
        },
        setCreateDate: (state, createDate) => {
            state.createDate = createDate;
        },
        setTracky: (state, tracky) => {
            state.tracky = tracky;
        },
        setModule: (state, module) => {
            let index = state.modules.findIndex((m) => m.type === module.type);
            if (index >= 0) state.modules.splice(index, 1);
            state.modules.push(module);
        },
    },
    strict: debug,
});
