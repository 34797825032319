<template>
  <v-row align="center" align-content="center">
    <v-spacer />
    <v-col cols="6">
      <v-card>
        <v-card-text>
          <v-file-input
            accept="application/json"
            @change="uploadFile"
          ></v-file-input>
        </v-card-text>
      </v-card>
    </v-col>
    <v-spacer />
  </v-row>
</template>

<script>
import variationColor from "@/mixins/variation-color";
import themePicker from "@/constants/theme-picker-constants";
import Tracky from "@/constants/tracky-constants";
import ThemeVuetify from "@/constants/theme-vuetify-constants";

export default {
  name: "upload-config",
  mixins: [variationColor],
  data() {
    return {};
  },
  computed: {
    themes() {
      return themePicker.themes;
    },
  },
  methods: {
    async uploadFile(files) {
      if (!files || files.length) return;
      const result = await this.readFile(files);
      const json = JSON.parse(result);
      this.setStore(json);
      this.$router.push("/");
    },
    setStore(json) {
      this.$store.commit("setLicenseCode", json.LicenseCode);
      this.$store.commit("setRefClient", json.RefClient);
      this.$store.commit("setTheme", json.Theme);
      this.$store.commit("setHeader", {
        color: json.Header.Color,
        hidden: json.Header.Hidden,
        image: json.Header.Image,
        position: json.Header.Position,
        size: json.Header.Size,
      });
      this.$store.commit("setFooter", {
        color: json.Footer.Color,
        hidden: json.Footer.Hidden,
        image: json.Footer.Image,
        position: json.Footer.Position,
        size: json.Footer.Size,
      });
      this.$store.commit("setTypo", {
        color: json.Typo.Color,
        font: {
          name: json.Typo.Font.Name,
          value: json.Typo.Font.Value,
        },
        size: json.Typo.Size,
      });
      this.$store.commit("setBackground", {
        color: json.Background.Color,
        hidden: json.Background.Hidden,
        image: json.Background.Image,
        position: json.Background.Position,
        size: json.Background.Size,
      });
      this.$store.commit("setComment", json.Comment);
      this.$store.commit("setCssUrl", json.CssUrl);
      this.$store.commit("setCreateDate", json.CreateDate);
      this.$store.commit("setLastUpdateDate", json.LastUpdateDate);
      this.setVariationColors(json.VariationColor, json.Theme);
      this.setPictos(json.Pictos);
      this.setTracky(json.Tracky);
      this.setModules(json.Modules);
    },
    setModules(modules) {
      if (!modules)
        Object.entries(ThemeVuetify).forEach((e) => {
          this.$store.commit("setModule", {
            type: e[0],
            colors: e[1].colors,
            isActive: e[1].isActive,
          });
        });
      else
        modules.forEach((m) => {
          this.$store.commit("setModule", {
            type: m.Type,
            colors: JSON.parse(m.Colors),
            isActive: m.IsActive,
          });
        });
    },
    setTracky(tracky) {
      if (!tracky) this.$store.commit("setTracky", Object.assign({}, Tracky));
      else
        this.$store.commit("setTracky", {
          isActive: tracky.IsActive,
          colors: this.strToVariationColor(
            tracky.Colors,
            Object.assign({}, Tracky.colors)
          ),
          image: tracky.Image,
        });
    },
    setPictos(pictos) {
      const pictosKeys = Object.keys(pictos);
      for (let index = 0; index < pictosKeys.length; index++) {
        const pictosKey = pictosKeys[index];
        if (!pictos[pictosKey]) continue;
        const picto = pictos[pictosKey].map((picto) => ({
          codes: picto.Codes,
          picto: {
            isCustom: picto.Picto.IsCustom,
            colors: picto.Picto.Colors,
            name: picto.Picto.Name,
            source: picto.Picto.Source,
            style: picto.Picto.Style,
          },
        }));
        this.$store.commit("setPictos", {
          type: pictosKey.decapitalize(),
          pictos: picto,
        });
      }
    },
    setVariationColors(variationColor, theme) {
      const colors = this.strToVariationColor(
        variationColor,
        Object.assign(
          {},
          this.themes[this.themes.findIndex((t) => t.name === theme)]
            .variations[0].colors
        )
      );
      this.$store.commit("setVariationColor", {
        colors: colors,
        disabled: false,
        name: "custom",
      });
    },
    readFile(file) {
      return new Promise((resolve, reject) => {
        var fr = new FileReader();
        fr.onload = () => {
          resolve(fr.result);
        };
        fr.onerror = reject;
        fr.readAsText(file);
      });
    },
  },
};
</script>

<style>
</style>
