const themePicker = {
    themes: [
        {
            name: 'rounded',
            variations: [
                {
                    name: 'default',
                    colors: {
                        mainColor: '#4a0031',
                        stepColorActive: '#006047',
                        stepColorTextActive: '#ffffff',
                        stepColorCircleActive: '#ffffff',
                        stepColorNumberActive: '#333333',
                        tabColor: '#444444',
                    },
                    disabled: false,
                },
                {
                    name: 'carotte et radis',
                    colors: {
                        mainColor: '#a6c462',
                        stepColorActive: '#cb415a',
                        stepColorTextActive: '#ffffff',
                        stepColorCircleActive: '#ffffff',
                        stepColorNumberActive: '#333333',
                        tabColor: '#025600',
                    },
                    disabled: false,
                },
                {
                    name: 'tons de vert',
                    colors: {
                        mainColor: '#3f8212',
                        stepColorActive: '#a1c801',
                        stepColorTextActive: '#ffffff',
                        stepColorCircleActive: '#ffffff',
                        stepColorNumberActive: '#333333',
                        tabColor: '#444444',
                    },
                    disabled: false,
                },
                {
                    name: 'méditerranée',
                    colors: {
                        mainColor: '#5eaa9e',
                        stepColorActive: '#a6c462',
                        stepColorTextActive: '#ffffff',
                        stepColorCircleActive: '#ffffff',
                        stepColorNumberActive: '#333333',
                        tabColor: '#55a7d2',
                    },
                    disabled: false,
                },
                {
                    name: 'custom',
                    colors: {
                        mainColor: null,
                        stepColorActive: null,
                        stepColorTextActive: null,
                        stepColorCircleActive: null,
                        stepColorNumberActive: null,
                        tabColor: null,
                    },
                    disabled: true,
                },
            ],
            typos: {
                fonts: [
                    {
                        name: 'Montserrat Regular',
                        value: "'montserratregular', Georgia, sans-serif",
                    },
                ],
                size: 13,
                color: '#333333FF',
            },
            backgroundColor: '#e5e9ec',
            idealHeaderHeight: 170,
            idealHeaderWidth: 1200,
            idealFooterHeight: 173,
            idealFooterWidth: 1200,
        },
        {
            name: 'flat',
            variations: [
                {
                    name: 'default',
                    colors: {
                        mainColorRegular: '#0077b1',
                        stepColorInactive: '#9999cc',
                        searchColorRegular: '#8aaad9',
                    },
                    disabled: false,
                },
                {
                    name: 'disco',
                    colors: {
                        mainColorRegular: '#fa6900',
                        stepColorInactive: '#a2ae19',
                        searchColorRegular: '#5e3489',
                    },
                    disabled: false,
                },
                {
                    name: 'forêt',
                    colors: {
                        mainColorRegular: '#728c80',
                        stepColorInactive: '#714f47',
                        searchColorRegular: '#5f7364',
                    },
                    disabled: false,
                },
                {
                    name: 'custom',
                    colors: {
                        mainColorRegular: null,
                        stepColorInactive: null,
                        searchColorRegular: null,
                    },
                    disabled: true,
                },
            ],
            typos: {
                fonts: [
                    {
                        name: 'OpenSans Regular',
                        value: "'open_sanslight', Arial, sans-serif;",
                    },
                ],
                size: 12,
                color: '#3c3c3c',
            },
            backgroundColor: '#EFEFEF',
            idealHeaderHeight: 100,
            idealHeaderWidth: 1200,
            idealFooterHeight: 112,
            idealFooterWidth: 1024,
        },
    ],
};

export default themePicker;
