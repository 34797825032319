<template>
  <main-layout>
    <template v-slot:title>
      <h1>{{ translate("create.title") }}</h1>
      <h2>{{ translate("create.subTitle") }}</h2>
    </template>
    <template v-slot:content>
      <stepper></stepper>
    </template>
  </main-layout>
</template>

<script>
import MainLayout from "./layouts/main";
import Stepper from "@/components/step/stepper.vue";

export default {
  name: "create",
  components: {
    MainLayout,
    Stepper,
  },
  data: () => ({}),
};
</script>
